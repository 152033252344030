import React from "react";

// reactstrap components
import {
  Col,
  Row,
  CardBody,
  Card,
  Container,
  CardTitle,
} from "reactstrap";
// core components

function PresentationHeader() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="px-0" md="10">
            <Card
              className="card-fashion page-header-image"
              style={{
                backgroundSize: "100% 100%",
                backgroundImage:
                  "url(" + require("assets/img/Notaria/fondoEncabezado.jpg") + ")",
              }}
            >
              <CardBody>
                <CardTitle className="text-left" tag="div">
                  <div className="rellax-text-container rellax-text">
                    <h1 className="title" data-rellax-speed="-1" 
                        style={{marginTop: "-300px", fontSize: "500%", color : "#bd0b0ed8"}}>
                      Notaría 2 de Floridablanca
                    </h1>
                  </div>
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
          <Col className="px-0" md="2">
            <Card className="card-fashion arrow-left">
              <CardTitle tag="div">
                <h5>
                  <br></br> 
                  <br></br>
                  <i className="now-ui-icons tech_watch-time" style={{fontSize: "25px", color:"#bd0b0e"}}></i>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <b>Lunes a Viernes</b>
                  <br></br>
                    7:30 AM - 12:15 PM, 2:00 PM - 6:00 PM
                  <br></br> 
                  <b style={{color:"#bd0b0e"}}>Sábados</b>
                  <br></br>
                  <a href="https://www.contadorvisitasgratis.com" title="contador de visitas web">
                  <img src="https://counter8.optistats.ovh/private/contadorvisitasgratis.php?c=9c9sys3q5mbfyudmpf5m1k29ym7fu3qy" border="0" title="contador de visitas web" alt="contador de visitas web"/></a>
                </h5>
              </CardTitle>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PresentationHeader;
